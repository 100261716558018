<template>
  <div class="w-full bg-gray1">
    <div class="m-auto max-w-7xl px-3 pb-20 md:px-6">
      <Breadcrumbs :breadcrumbs="breadcrumbs" class="py-2 md:py-5" />

      <Separator />

      <h1 class="mt-4">{{ t("ui.breadcrumbs.categories", 2) }}</h1>
    </div>
  </div>
  <section class="m-auto max-w-7xl">
    <CategoryListRoot v-if="data?.categories?.edges.length">
      <CategoryListItem
        v-for="category in data?.categories?.edges"
        :key="category.node.id"
        :title="category.node.translation?.name ?? category.node.name"
        :subtitle="category.node.translation?.description ?? category.node.description ?? ''"
        :href="`/categories/${category.node.slug}`"
      />
    </CategoryListRoot>
    <h2 v-else-if="status === 'success'">{{ $t("categories.noCategoriesFound") }}</h2>
  </section>
</template>
<script setup lang="ts">
import { CategoriesDocument } from "@graphql";

import CategoryListItem from "~/src/categories/components/ListItem.vue";
import CategoryListRoot from "~/src/categories/components/ListRoot.vue";

const { locale, t } = useI18n();
const { data, status } = useAsyncQuery({
  query: CategoriesDocument,
  variables: computed(() => ({
    languageCode: toLanguageCode(locale.value),
  })),
});

const breadcrumbs = computed<Breadcrumb[]>(() => [
  {
    label: t("ui.breadcrumbs.categories"),
    href: "/categories",
  },
]);

useHead({
  title: t("categories.title", 2),
});
</script>
